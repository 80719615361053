// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/icon-meta.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/img/walletConnect.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/img/coinbase.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\nh4[data-v-5e24a638] {\n  font-size: 1rem;\n  line-height: 1.35;\n  font-weight: 500;\n  text-align: center;\n}\nh4 a[data-v-5e24a638] {\n  color: var(--purple);\n}\n.connect[data-v-5e24a638] {\n  cursor: pointer;\n  background-color: var(--lightpurple);\n  color: var(--purple);\n  padding: 10px 15px;\n  border-radius: 5px;\n}\n.curtain[data-v-5e24a638] {\n  position: fixed;\n  z-index: 999;\n  width: 100vw;\n  height: 100vh;\n  background: rgba(0,0,0,0.35);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n.popup[data-v-5e24a638] {\n  display: block;\n  width: 100%;\n  max-width: 450px;\n  background: white;\n  border-radius: 2px;\n  padding: 40px;\n}\n.popup .header[data-v-5e24a638] {\n  text-align: center;\n  margin-bottom: 30px;\n}\nul[data-v-5e24a638] {\n  line-height: 2;\n}\n.options[data-v-5e24a638], h4[data-v-5e24a638] {\n  margin-bottom: 30px;\n}\n.option[data-v-5e24a638] {\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  width: 100%;\n  min-height: 72px;\n  border-radius: 5px;\n  border: 1px solid rgb(245, 245, 245);\n  margin-bottom: 10px;\n  cursor: pointer;\n}\n.badge[data-v-5e24a638] {\n  position: relative;\n  width: 50px;\n  height: 50px;\n  background: #eee;\n  border: 1px solid #eee;\n  border-radius: 100%;\n  flex-grow: unset;\n  flex-shrink: 0;\n  transition: transform 100ms;\n  cursor: pointer;\n}\n.badge.meta[data-v-5e24a638] {\n  background:white url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center/70% no-repeat;\n}\n.badge.wc[data-v-5e24a638] {\n  background:white url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") center/70% no-repeat;\n}\n.badge.coinbase[data-v-5e24a638] {\n  background:white url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") center/70% no-repeat;\n}\n.option .badge[data-v-5e24a638] {\n  width: 50px;\n  height: 50px;\n  border-radius: 100%;\n  margin-right: 10px;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
